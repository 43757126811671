import axios from "axios";

import { useState } from "react";
import "./HomePage.css";
import { URL } from "./socket";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function HomePage(): JSX.Element | undefined {
  const [gameCode, setGameCode] = useState<string>("");
  const navigate = useNavigate();
  const handleSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    if (gameCode.length <= 0) {
      return;
    }
    axios
      .get(`${URL}/api/lobby/${gameCode}`, {
        withCredentials: true,
      })
      .then(() => {
        navigate(`/lobby/${gameCode}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCreateNewGame = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    axios
      .get(`${URL}/api/new_game`, { withCredentials: true })
      .then((response) => {
        navigate(`/lobby/${response.data.game_id}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="home-container">
      <div className="home">
        <h1 className="title">
          <span className="magic-text">斗地主</span> / Fight The Landlord
        </h1>
        <h2 className="title-header">Join or Create a Game</h2>
        
        <p className="text-title-subtext font-medium m-0 text-lg leading-7 tracking-wide">
          
          Welcome to the game! Enter a game code below to join an existing game,
          or create a new game for your friends to join.
        </p>
        <form className="generate-lobby-container" onSubmit={handleSubmit}>
          <input
            className="room-code-input"
            type="text"
            placeholder="Enter 4 letter game code"
            value={gameCode}
            onChange={(e) => setGameCode(e.target.value.toUpperCase())}
          />
          <input className="join-button" type="submit" value="Join Game" />
        </form>
        <div className="divider">
          <hr></hr> or <hr></hr>
        </div>
        <button className="create-button" onClick={handleCreateNewGame}>
          Create Game
        </button>
        <div className="footer">
          Don't know how to play?
          <Link
            className="footer-link"
            to="https://www.pagat.com/climbing/doudizhu.html"
          >
            Read this first
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
