import { CardComponentSVG } from "../helper_util";
import { Card, GameStage, GameState, Player } from "../types/types";
import PlayerField from "./PlayerField";

interface GameFieldProps {
  gameState?: GameState;
  player?: Player;
  right?: Player;
  left?: Player;
  top?: Player;
}

interface GameStateProps {
  gameState?: GameState;
}

const GameField = ({ gameState, player, right, left, top }: GameFieldProps) => {
  // const bidding = gameState?.game_stage === GameStage.BIDDING;
  // const playing = gameState?.game_stage === GameStage.PLAYING;
  return (
    <div className="game-field">
      <GameFieldText gameState={gameState} />
      <GameFieldCards gameState={gameState} />
      <PlayerField player={player} position="you" gameState={gameState} />
      <PlayerField player={right} position="right" gameState={gameState} />
      <PlayerField player={top} position="top" gameState={gameState} />
      <PlayerField player={left} position="left" gameState={gameState} />
      <GameFieldCombinationText gameState={gameState} />
    </div>
  );
};

const GameFieldText = ({ gameState }: GameStateProps) => {
  const lobby = gameState?.game_stage === GameStage.LOBBY;
  if (lobby) {
    if (gameState?.players.length === 4) {
      return <p className="game-field-text">Ready to start the game...</p>;
    }
    return <p className="game-field-text">Waiting for players...</p>;
  }

  const bidding = gameState?.game_stage === GameStage.BIDDING;
  if (bidding) {
    const lastToBid = gameState?.players[gameState?.last_to_bid_idx]?.name;
    return (
      <>
        <p className="game-field-text">
          Betting round, winner takes the kitty.
        </p>
        {gameState?.game_bid > 0 && (
          <p className="game-field-center">
            {lastToBid} bid {gameState.game_bid}.
          </p>
        )}
      </>
    );
  }

  const playing = gameState?.game_stage === GameStage.PLAYING;
  if (playing) {
    const lastToPlay =
      gameState?.players[gameState?.game_field.last_to_play_idx]?.name;
    return gameState?.game_field.field_cards.length > 0 ? (
      <p className="game-field-text">{lastToPlay} played</p>
    ) : undefined;
  }

  return gameState?.game_stage === GameStage.FINISHED ? (
    <p className="game-field-center">
      {gameState.winner === "Landlord" ? "Landlord wins!" : "Peasants win!"}
    </p>
  ) : undefined;
};

const GameFieldCards = ({ gameState }: GameStateProps) => {
  const cards = gameState?.game_field.field_cards;
  if (!cards) {
    return null;
  }
  return (
    <div className="game-field-cards">
      {cards.map((card: Card, index: number) => {
        return <CardComponentSVG card={card} index={index} small={cards.length >= 12} />
      })}
    </div>
  );
};

const GameFieldCombinationText = ({ gameState }: GameStateProps) => {
  const combination = gameState?.game_field.field_combination_type;
  if (!combination) {
    return null;
  }
  return <div className="game-field-combination-text">{combination}</div>;
};

export default GameField;
