import { useEffect, useState } from "react";
import "./Game.css";
import { URL, socket } from "./socket";
import { useNavigate } from "react-router-dom";
import { getPlayerArrangement } from "./helper_util";
import AudioPlayer from "./components/AudioPlayer";
import MessageLogs from "./components/MessageLogs";
import GameField from "./components/GameField";
import GameNotification from "./components/GameNotification";
import Sidebar from "./components/Sidebar";
import PlayerHand from "./components/PlayerHand";
import ActionButton from "./components/ActionButton";
import { GameState } from "./types/types";
import axios from "axios";

function Game(): JSX.Element | undefined {
  const navigate = useNavigate();
  const [gameState, setGameState] = useState<GameState>();
  const [selectedCards, setSelectedCards] = useState(
    {} as { [key: string]: boolean }
  );
  const [playerArrangement, setPlayerArrangement] = useState({
    player: undefined,
    right: undefined,
    left: undefined,
    top: undefined,
  });
  let gameCode = window.location.pathname.split("/").pop();

  useEffect(() => {
    if (gameState) {
      setPlayerArrangement(
        getPlayerArrangement(gameState.player_self, gameState.players)
      );
    }
  }, [gameState]);

  const tryReconnectSession = () => {
    axios
      .get(`${URL}/api/reconnect_session/${gameCode}`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.success) {
          socket.emit("reconnect", {
            game_id: gameCode,
            username: response.data.username,
          });
        } else {
          navigate("/lobby/" + gameCode);
        }
      });
  };

  const updateGameState = (gameState: GameState) => {
    setGameState(gameState);
  };

  // We need this resetSession incase two different sessions use same names. The game currently auto generates a new username for the
  // second session and thats why we need the session to remember the new username.
  const resetSession = (data: any) => {
    axios
      .post(
        `${URL}/api/set_client_session`,
        {
          username: data["username"],
          game_id: data["game_id"],
        },
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        console.log(error);
        navigate("/", { state: "Error" });
      });
  };

  useEffect(() => {
    socket.on("connect", tryReconnectSession);
    socket.on("game_state", updateGameState);
    socket.on("reset_username", resetSession);

    return () => {
      socket.off("connect", tryReconnectSession);
      socket.off("game_state", updateGameState);
      socket.off("reset_username", resetSession);
    };
  });

  return (
    <div className="game-main-container">
      <Sidebar gameState={gameState} />
      <AudioPlayer />
      <GameNotification gameState={gameState} />
      <GameField gameState={gameState} {...playerArrangement} />
      <MessageLogs gameState={gameState} />
      <PlayerHand
        playerSelf={gameState?.player_self}
        gameStage={gameState?.game_stage}
        num_players={gameState?.players.length}
        selectedCards={selectedCards}
        setSelectedCards={setSelectedCards}
      />
      <ActionButton
        gameState={gameState}
        selectedCards={selectedCards}
        setSelectedCards={setSelectedCards}
      />
    </div>
  );
}

export default Game;
