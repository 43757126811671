import { useEffect, useState } from "react";
import Chevron from "react-chevron";
import { Card, PlayerSelf, GameStage } from "../types/types";
import { CardComponentSVG } from "../helper_util";

interface PlayerHandProps {
  playerSelf?: PlayerSelf;
  gameStage: string;
  selectedCards: { [key: string]: boolean };
  setSelectedCards: any;
  num_players?: number;
}

const PlayerHand = ({
  gameStage,
  playerSelf,
  selectedCards,
  setSelectedCards,
  num_players,
}: PlayerHandProps) => {
  const toggleSelect = (index: number) => {
    setSelectedCards((prevSelected: { [x: number]: boolean }) => {
      return {
        ...prevSelected,
        [index]: !prevSelected[index],
      };
    });
  };

  const [mouseDown, setMouseDown] = useState<boolean>(false);

  const handleMouseDown = () => {
    setMouseDown(true);
  };

  const handleMouseUp = () => {
    setMouseDown(false);
  }

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return playerSelf ? (
    <>
      <div className="game-card-hand">
        {gameStage === GameStage.PLAYING && (
          <div onClick={handleCollapse}>
            <Chevron className={`collapse-button-1${num_players === 4 ? "-four-player" : ""}`} direction={collapsed ? "up" : "down"}></Chevron>
            <Chevron className={`collapse-button-2${num_players === 4 ? "-four-player" : ""}`} direction={collapsed ? "up" : "down"}></Chevron>
          </div>
        )}
        {playerSelf.cards.map((card: Card, index: number) => {
          return <CardComponentSVG card={card} key={index} index={index} selected={selectedCards[index]} toggleSelect={gameStage === GameStage.PLAYING ? toggleSelect : undefined} hidden={collapsed} small={num_players === 4} mouseDown={mouseDown}/>;
        })}
      </div>
    </>
  ) : null;
};

export default PlayerHand;
