export interface Card {
  suit: string;
  value: string;
}

export interface PlayerSelf {
  name: string;
  sid: string;
  role: string;
  cards: Card[];
  score: number;
  color: string;
}

export interface GameField {
  field_cards: Card[];
  last_to_play_idx: number;
  field_combination_type: string;
}

export enum MessageType {
  PLAYER_MESSAGE = "player_message",
  GAME_MESSAGE = "game_message",
}

export interface Message {
  message: string;
  message_type: MessageType;
  player: Player | undefined;
}

export enum GameStage {
  LOBBY = "lobby",
  BIDDING = "bidding",
  PLAYING = "playing",
  FINISHED = "finished",
}

export interface Player {
  name: string;
  sid: string;
  role: string;
  score: number;
  num_cards_left: number;
  index: number;
  color: string;
}

export enum GameAction {
  PLAYER_JOIN = "PLAYER_JOIN",
  BID_START = "BID_START",
  BID = "BID",
  PASS_BID = "PASS_BID",
  PLAY_START = "PLAY_START",
  PLAY = "PLAY",
  PASS_PLAY = "PASS_PLAY",
  INVALID_PLAY = "INVALID_PLAY",
  BOMB = "BOMB",
  ROCKET = "ROCKET",
  GAME_OVER = "GAME_OVER",
}

export interface GameState {
  game_id: string;
  current_player_index: number;
  player_self: PlayerSelf;
  players: Player[];
  game_field: GameField;
  landlord_index: number;
  landlord_bonus: Card[];
  game_stage: string;
  game_bid: number;
  last_to_bid_idx: number;
  winner: string;
  messages: Message[];
  passed_players_idx: number[];
}
