import { useEffect, useRef, useState } from "react";

import { socket } from "../socket";
import { GameState, Message } from "../types/types";

interface MessageLogsProps {
  gameState?: GameState;
}

const MessageLogs = ({ gameState }: MessageLogsProps) => {
  const bottomRef = useRef(null);
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [gameState?.messages]);

  const [message, setMessage] = useState<string>("");

  const handleEnter = (event: any) => {
    if (event.code === "Enter" && message.length > 0) {
      setMessage("");
      socket.emit("message", {
        game_id: gameState?.game_id,
        message: message,
      });
    }
  };

  const handleChange = (event: any) => {
    setMessage(event.target.value);
  };

  const messageColor = (message: Message) => {
    return message.player ? message.player.color : "#686B6E";
  };

  return (
    <>
      <div className="game-log">
        {gameState?.messages.map((message, i) => {
          return (
            <p key={i} style={{ margin: "0" }}>
              <b style={{ color: messageColor(message) }}>
                {message.player?.name ?? "Game"}:
              </b>{" "}
              {message.message}
            </p>
          );
        })}
        <div ref={bottomRef}></div>
      </div>
      <input
        onKeyDown={handleEnter}
        onChange={handleChange}
        className="game-input-log"
        value={message}
        placeholder="Say something!"
      ></input>
    </>
  );
};

export default MessageLogs;
