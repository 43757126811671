import { useState } from "react";
import { Card, Player, PlayerSelf } from "./types/types";
import { ReactComponent as ten_club_mini } from "./assets/cards/10_club_mini.svg";
import { ReactComponent as ten_club_regular } from "./assets/cards/10_club_regular.svg";
import { ReactComponent as ten_diamond_mini } from "./assets/cards/10_diamond_mini.svg";
import { ReactComponent as ten_diamond_regular } from "./assets/cards/10_diamond_regular.svg";
import { ReactComponent as ten_heart_mini } from "./assets/cards/10_heart_mini.svg";
import { ReactComponent as ten_heart_regular } from "./assets/cards/10_heart_regular.svg";
import { ReactComponent as ten_spade_mini } from "./assets/cards/10_spade_mini.svg";
import { ReactComponent as ten_spade_regular } from "./assets/cards/10_spade_regular.svg";
import { ReactComponent as two_club_mini } from "./assets/cards/2_club_mini.svg";
import { ReactComponent as two_club_regular } from "./assets/cards/2_club_regular.svg";
import { ReactComponent as two_diamond_mini } from "./assets/cards/2_diamond_mini.svg";
import { ReactComponent as two_diamond_regular } from "./assets/cards/2_diamond_regular.svg";
import { ReactComponent as two_heart_mini } from "./assets/cards/2_heart_mini.svg";
import { ReactComponent as two_heart_regular } from "./assets/cards/2_heart_regular.svg";
import { ReactComponent as two_spade_mini } from "./assets/cards/2_spade_mini.svg";
import { ReactComponent as two_spade_regular } from "./assets/cards/2_spade_regular.svg";
import { ReactComponent as three_club_mini } from "./assets/cards/3_club_mini.svg";
import { ReactComponent as three_club_regular } from "./assets/cards/3_club_regular.svg";
import { ReactComponent as three_diamond_mini } from "./assets/cards/3_diamond_mini.svg";
import { ReactComponent as three_diamond_regular } from "./assets/cards/3_diamond_regular.svg";
import { ReactComponent as three_heart_mini } from "./assets/cards/3_heart_mini.svg";
import { ReactComponent as three_heart_regular } from "./assets/cards/3_heart_regular.svg";
import { ReactComponent as three_spade_mini } from "./assets/cards/3_spade_mini.svg";
import { ReactComponent as three_spade_regular } from "./assets/cards/3_spade_regular.svg";
import { ReactComponent as four_club_mini } from "./assets/cards/4_club_mini.svg";
import { ReactComponent as four_club_regular } from "./assets/cards/4_club_regular.svg";
import { ReactComponent as four_diamond_mini } from "./assets/cards/4_diamond_mini.svg";
import { ReactComponent as four_diamond_regular } from "./assets/cards/4_diamond_regular.svg";
import { ReactComponent as four_heart_mini } from "./assets/cards/4_heart_mini.svg";
import { ReactComponent as four_heart_regular } from "./assets/cards/4_heart_regular.svg";
import { ReactComponent as four_spade_mini } from "./assets/cards/4_spade_mini.svg";
import { ReactComponent as four_spade_regular } from "./assets/cards/4_spade_regular.svg";
import { ReactComponent as five_club_mini } from "./assets/cards/5_club_mini.svg";
import { ReactComponent as five_club_regular } from "./assets/cards/5_club_regular.svg";
import { ReactComponent as five_diamond_mini } from "./assets/cards/5_diamond_mini.svg";
import { ReactComponent as five_diamond_regular } from "./assets/cards/5_diamond_regular.svg";
import { ReactComponent as five_heart_mini } from "./assets/cards/5_heart_mini.svg";
import { ReactComponent as five_heart_regular } from "./assets/cards/5_heart_regular.svg";
import { ReactComponent as five_spade_mini } from "./assets/cards/5_spade_mini.svg";
import { ReactComponent as five_spade_regular } from "./assets/cards/5_spade_regular.svg";
import { ReactComponent as six_club_mini } from "./assets/cards/6_club_mini.svg";
import { ReactComponent as six_club_regular } from "./assets/cards/6_club_regular.svg";
import { ReactComponent as six_diamond_mini } from "./assets/cards/6_diamond_mini.svg";
import { ReactComponent as six_diamond_regular } from "./assets/cards/6_diamond_regular.svg";
import { ReactComponent as six_heart_mini } from "./assets/cards/6_heart_mini.svg";
import { ReactComponent as six_heart_regular } from "./assets/cards/6_heart_regular.svg";
import { ReactComponent as six_spade_mini } from "./assets/cards/6_spade_mini.svg";
import { ReactComponent as six_spade_regular } from "./assets/cards/6_spade_regular.svg";
import { ReactComponent as seven_club_mini } from "./assets/cards/7_club_mini.svg";
import { ReactComponent as seven_club_regular } from "./assets/cards/7_club_regular.svg";
import { ReactComponent as seven_diamond_mini } from "./assets/cards/7_diamond_mini.svg";
import { ReactComponent as seven_diamond_regular } from "./assets/cards/7_diamond_regular.svg";
import { ReactComponent as seven_heart_mini } from "./assets/cards/7_heart_mini.svg";
import { ReactComponent as seven_heart_regular } from "./assets/cards/7_heart_regular.svg";
import { ReactComponent as seven_spade_mini } from "./assets/cards/7_spade_mini.svg";
import { ReactComponent as seven_spade_regular } from "./assets/cards/7_spade_regular.svg";
import { ReactComponent as eight_club_mini } from "./assets/cards/8_club_mini.svg";
import { ReactComponent as eight_club_regular } from "./assets/cards/8_club_regular.svg";
import { ReactComponent as eight_diamond_mini } from "./assets/cards/8_diamond_mini.svg";
import { ReactComponent as eight_diamond_regular } from "./assets/cards/8_diamond_regular.svg";
import { ReactComponent as eight_heart_mini } from "./assets/cards/8_heart_mini.svg";
import { ReactComponent as eight_heart_regular } from "./assets/cards/8_heart_regular.svg";
import { ReactComponent as eight_spade_mini } from "./assets/cards/8_spade_mini.svg";
import { ReactComponent as eight_spade_regular } from "./assets/cards/8_spade_regular.svg";
import { ReactComponent as nine_club_mini } from "./assets/cards/9_club_mini.svg";
import { ReactComponent as nine_club_regular } from "./assets/cards/9_club_regular.svg";
import { ReactComponent as nine_diamond_mini } from "./assets/cards/9_diamond_mini.svg";
import { ReactComponent as nine_diamond_regular } from "./assets/cards/9_diamond_regular.svg";
import { ReactComponent as nine_heart_mini } from "./assets/cards/9_heart_mini.svg";
import { ReactComponent as nine_heart_regular } from "./assets/cards/9_heart_regular.svg";
import { ReactComponent as nine_spade_mini } from "./assets/cards/9_spade_mini.svg";
import { ReactComponent as nine_spade_regular } from "./assets/cards/9_spade_regular.svg";
import { ReactComponent as A_club_mini } from "./assets/cards/A_club_mini.svg";
import { ReactComponent as A_club_regular } from "./assets/cards/A_club_regular.svg";
import { ReactComponent as A_diamond_mini } from "./assets/cards/A_diamond_mini.svg";
import { ReactComponent as A_diamond_regular } from "./assets/cards/A_diamond_regular.svg";
import { ReactComponent as A_heart_mini } from "./assets/cards/A_heart_mini.svg";
import { ReactComponent as A_heart_regular } from "./assets/cards/A_heart_regular.svg";
import { ReactComponent as A_spade_mini } from "./assets/cards/A_spade_mini.svg";
import { ReactComponent as A_spade_regular } from "./assets/cards/A_spade_regular.svg";
import { ReactComponent as J_club_mini } from "./assets/cards/J_club_mini.svg";
import { ReactComponent as J_club_regular } from "./assets/cards/J_club_regular.svg";
import { ReactComponent as J_diamond_mini } from "./assets/cards/J_diamond_mini.svg";
import { ReactComponent as J_diamond_regular } from "./assets/cards/J_diamond_regular.svg";
import { ReactComponent as J_heart_mini } from "./assets/cards/J_heart_mini.svg";
import { ReactComponent as J_heart_regular } from "./assets/cards/J_heart_regular.svg";
import { ReactComponent as J_spade_mini } from "./assets/cards/J_spade_mini.svg";
import { ReactComponent as J_spade_regular } from "./assets/cards/J_spade_regular.svg";
import { ReactComponent as K_club_mini } from "./assets/cards/K_club_mini.svg";
import { ReactComponent as K_club_regular } from "./assets/cards/K_club_regular.svg";
import { ReactComponent as K_diamond_mini } from "./assets/cards/K_diamond_mini.svg";
import { ReactComponent as K_diamond_regular } from "./assets/cards/K_diamond_regular.svg";
import { ReactComponent as K_heart_mini } from "./assets/cards/K_heart_mini.svg";
import { ReactComponent as K_heart_regular } from "./assets/cards/K_heart_regular.svg";
import { ReactComponent as K_spade_mini } from "./assets/cards/K_spade_mini.svg";
import { ReactComponent as K_spade_regular } from "./assets/cards/K_spade_regular.svg";
import { ReactComponent as Q_club_mini } from "./assets/cards/Q_club_mini.svg";
import { ReactComponent as Q_club_regular } from "./assets/cards/Q_club_regular.svg";
import { ReactComponent as Q_diamond_mini } from "./assets/cards/Q_diamond_mini.svg";
import { ReactComponent as Q_diamond_regular } from "./assets/cards/Q_diamond_regular.svg";
import { ReactComponent as Q_heart_mini } from "./assets/cards/Q_heart_mini.svg";
import { ReactComponent as Q_heart_regular } from "./assets/cards/Q_heart_regular.svg";
import { ReactComponent as Q_spade_mini } from "./assets/cards/Q_spade_mini.svg";
import { ReactComponent as Q_spade_regular } from "./assets/cards/Q_spade_regular.svg";
import { ReactComponent as SmallJoker_SmallJoker_regular } from "./assets/cards/SmallJoker_SmallJoker_regular.svg";
import { ReactComponent as BigJoker_BigJoker_regular } from "./assets/cards/BigJoker_BigJoker_regular.svg";

export const getPlayerArrangement = (
  player_self: PlayerSelf,
  players: Player[]
) => {
  const player = players.find((player) => player.name === player_self.name);
  if (players.length === 1) {
    return {
      player,
      right: undefined,
      left: undefined,
      top: undefined,
    };
  } else if (players.length === 2) {
    if (player.index === 0)
      return {
        player,
        right: players[1],
        left: undefined,
        top: undefined,
      };
    else {
      return {
        player,
        right: undefined,
        left: players[0],
        top: undefined,
      };
    }
  } else if (players.length === 3) {
    const rightIndex = (player.index + 1) % players.length;
    const leftIndex = (rightIndex + 1) % players.length;
    return {
      player,
      right: players[rightIndex],
      left: players[leftIndex],
      top: undefined,
    };
  } else {
    const rightIndex = (player.index + 1) % players.length;
    const topIndex = (rightIndex + 1) % players.length;
    const leftIndex = (topIndex + 1) % players.length;
    return {
      player,
      right: players[rightIndex],
      left: players[leftIndex],
      top: players[topIndex],
    };
  }
};

const cardComponents: { [key: string]: any } = {
  "10_club_mini": ten_club_mini,
  "10_club_regular": ten_club_regular,
  "10_diamond_mini": ten_diamond_mini,
  "10_diamond_regular": ten_diamond_regular,
  "10_heart_mini": ten_heart_mini,
  "10_heart_regular": ten_heart_regular,
  "10_spade_mini": ten_spade_mini,
  "10_spade_regular": ten_spade_regular,
  "2_club_mini": two_club_mini,
  "2_club_regular": two_club_regular,
  "2_diamond_mini": two_diamond_mini,
  "2_diamond_regular": two_diamond_regular,
  "2_heart_mini": two_heart_mini,
  "2_heart_regular": two_heart_regular,
  "2_spade_mini": two_spade_mini,
  "2_spade_regular": two_spade_regular,
  "3_club_mini": three_club_mini,
  "3_club_regular": three_club_regular,
  "3_diamond_mini": three_diamond_mini,
  "3_diamond_regular": three_diamond_regular,
  "3_heart_mini": three_heart_mini,
  "3_heart_regular": three_heart_regular,
  "3_spade_mini": three_spade_mini,
  "3_spade_regular": three_spade_regular,
  "4_club_mini": four_club_mini,
  "4_club_regular": four_club_regular,
  "4_diamond_mini": four_diamond_mini,
  "4_diamond_regular": four_diamond_regular,
  "4_heart_mini": four_heart_mini,
  "4_heart_regular": four_heart_regular,
  "4_spade_mini": four_spade_mini,
  "4_spade_regular": four_spade_regular,
  "5_club_mini": five_club_mini,
  "5_club_regular": five_club_regular,
  "5_diamond_mini": five_diamond_mini,
  "5_diamond_regular": five_diamond_regular,
  "5_heart_mini": five_heart_mini,
  "5_heart_regular": five_heart_regular,
  "5_spade_mini": five_spade_mini,
  "5_spade_regular": five_spade_regular,
  "6_club_mini": six_club_mini,
  "6_club_regular": six_club_regular,
  "6_diamond_mini": six_diamond_mini,
  "6_diamond_regular": six_diamond_regular,
  "6_heart_mini": six_heart_mini,
  "6_heart_regular": six_heart_regular,
  "6_spade_mini": six_spade_mini,
  "6_spade_regular": six_spade_regular,
  "7_club_mini": seven_club_mini,
  "7_club_regular": seven_club_regular,
  "7_diamond_mini": seven_diamond_mini,
  "7_diamond_regular": seven_diamond_regular,
  "7_heart_mini": seven_heart_mini,
  "7_heart_regular": seven_heart_regular,
  "7_spade_mini": seven_spade_mini,
  "7_spade_regular": seven_spade_regular,
  "8_club_mini": eight_club_mini,
  "8_club_regular": eight_club_regular,
  "8_diamond_mini": eight_diamond_mini,
  "8_diamond_regular": eight_diamond_regular,
  "8_heart_mini": eight_heart_mini,
  "8_heart_regular": eight_heart_regular,
  "8_spade_mini": eight_spade_mini,
  "8_spade_regular": eight_spade_regular,
  "9_club_mini": nine_club_mini,
  "9_club_regular": nine_club_regular,
  "9_diamond_mini": nine_diamond_mini,
  "9_diamond_regular": nine_diamond_regular,
  "9_heart_mini": nine_heart_mini,
  "9_heart_regular": nine_heart_regular,
  "9_spade_mini": nine_spade_mini,
  "9_spade_regular": nine_spade_regular,
  A_club_mini: A_club_mini,
  A_club_regular: A_club_regular,
  A_diamond_mini: A_diamond_mini,
  A_diamond_regular: A_diamond_regular,
  A_heart_mini: A_heart_mini,
  A_heart_regular: A_heart_regular,
  A_spade_mini: A_spade_mini,
  A_spade_regular: A_spade_regular,
  J_club_mini: J_club_mini,
  J_club_regular: J_club_regular,
  J_diamond_mini: J_diamond_mini,
  J_diamond_regular: J_diamond_regular,
  J_heart_mini: J_heart_mini,
  J_heart_regular: J_heart_regular,
  J_spade_mini: J_spade_mini,
  J_spade_regular: J_spade_regular,
  K_club_mini: K_club_mini,
  K_club_regular: K_club_regular,
  K_diamond_mini: K_diamond_mini,
  K_diamond_regular: K_diamond_regular,
  K_heart_mini: K_heart_mini,
  K_heart_regular: K_heart_regular,
  K_spade_mini: K_spade_mini,
  K_spade_regular: K_spade_regular,
  Q_club_mini: Q_club_mini,
  Q_club_regular: Q_club_regular,
  Q_diamond_mini: Q_diamond_mini,
  Q_diamond_regular: Q_diamond_regular,
  Q_heart_mini: Q_heart_mini,
  Q_heart_regular: Q_heart_regular,
  Q_spade_mini: Q_spade_mini,
  Q_spade_regular: Q_spade_regular,

  SmallJoker_SmallJoker_regular: SmallJoker_SmallJoker_regular,
  BigJoker_BigJoker_regular: BigJoker_BigJoker_regular,
};

export interface CardComponentSVGProps {
  card: Card;
  index: number;
  selected?: boolean;
  toggleSelect?: (index: number) => void;
  hidden?: boolean;
  small?: boolean;
  mouseDown?: boolean;
}

export const CardComponentSVG = ({
  card,
  index,
  selected,
  toggleSelect,
  hidden,
  small,
  mouseDown,
}: CardComponentSVGProps) => {
  const [cardMouseDown, setCardMouseDown] = useState<boolean>(false);
  const componentName = `${card.value}_${card.suit}_regular`;
  const CardComponent = cardComponents[componentName as string];
  const handleMouseEnter = () => {
    if (mouseDown && toggleSelect) {
      toggleSelect(index);
    }
  };
  const handleMouseLeave = () => {
    if (cardMouseDown && toggleSelect) {
      toggleSelect(index);
      setCardMouseDown(false);
    }
  };
  const handleClick = () => {
    if (toggleSelect) {
      toggleSelect(index);
    }
  };
  return (
    <CardComponent
      key={index}
      className={`game-card ${selected ? "card-selected" : ""} ${
        small ? "game-card-small" : ""
      } ${hidden && "game-card-hidden"}`}
      onMouseDown={() => {
        setCardMouseDown(true);
      }}
      onMouseUp={() => {
        setCardMouseDown(false);
      }}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      onClick={handleClick}
    />
  );
};
