import { GameStage, GameState } from "../types/types";

interface GameNotificationProps {
  gameState?: GameState;
}

const GameNotification = ({ gameState }: GameNotificationProps) => {
  const currentPlayerName = gameState?.players[gameState?.current_player_index]?.name;
  const bidding = gameState?.game_stage === GameStage.BIDDING;
  const playing = gameState?.game_stage === GameStage.PLAYING;

  const notificationText = (
    bidding ? `${currentPlayerName}'s turn to bid.` :
    playing ? `${currentPlayerName}'s turn to play.` :
    undefined
  );

  return notificationText ? (
    <div className="game-top-notification">{notificationText}</div>
  ) : undefined;
};

export default GameNotification;
