import { useEffect } from "react";
import { socket } from "../socket";
import { GameAction } from "../types/types";
import PassAudio from "../assets/audio/double_tap.mp3";
import InvalidPlayAudio from "../assets/audio/Quack_Sound_Effect.mp3";
import RocketAudio from "../assets/audio/League_of_Legends___Pentakill__Sound_Effect.mp3";
import BombAudio from "../assets/audio/bomb.mp3";
import PlayCardAudio from "../assets/audio/play_card_2.mp3";
import ShuffleCardsAudio from "../assets/audio/shuffle_cards_2.mp3";

const getSoundForGameAction = (gameAction: GameAction) => {
  switch (gameAction) {
    case GameAction.BID_START:
      return ShuffleCardsAudio;
    case GameAction.BOMB:
      return BombAudio;
    case GameAction.PASS_BID:
    case GameAction.PASS_PLAY:
      return PassAudio;
    case GameAction.INVALID_PLAY:
      return InvalidPlayAudio;
    case GameAction.ROCKET:
      return RocketAudio;
    case GameAction.PLAY:
      return PlayCardAudio;
    default:
      return undefined;
  }
};

const AudioPlayer = () => {
  const handleGameAction = (gameAction: GameAction) => {
    const gameActionSound = getSoundForGameAction(gameAction);
    if (gameActionSound) {
      const audio = new Audio(gameActionSound);
      audio.play();
    }
  };

  useEffect(() => {
    socket.on("game_action", handleGameAction);
    return () => {
      socket.off("game_action", handleGameAction);
    };
  });

  return <></>;
};

export default AudioPlayer;
