import "./Lobby.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { URL, socket } from "./socket";
import axios from "axios";

function Lobby(): JSX.Element | undefined {
  const navigate = useNavigate();
  // const [isConnected, setIsConnected] = useState(socket.connected);
  const [username, setUsername] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const inputFieldRef = useRef(null);
  let gameCode = window.location.pathname.split("/").pop();

  useEffect(() => {
    axios
      .get(`${URL}/api/lobby/${gameCode}`, {
        withCredentials: true,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        navigate("/", { state: "Error" });
      });
    if (inputFieldRef.current) {
      inputFieldRef.current.focus();
    }
  }, [gameCode, navigate]);

  const handleJoinGame = (event: React.SyntheticEvent): void => {
    event.preventDefault();

    if (!username || username.length < 1 || username.length > 14) {
      setErrorMessage("Username must be between 1 and 14 characters.");
      return;
    }
    axios
      .post(
       `${URL}/api/set_client_session`,
        {
          username: username,
          game_id: gameCode,
        },
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.success) {
          navigate("/game/" + gameCode);
          socket.emit("join", {
            game_id: gameCode,
            username: username,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        navigate("/", { state: "Error" });
      });
  };

  return (
    <div className="lobby-container">
      <div className="placeholder-background"></div>
      <div className="username-modal-container">
        <h1 className="modal-title">
          {" "}
          Welcome to <span className="game-code">{gameCode}</span>{" "}
        </h1>
        <div className="modal-input">
          <p className="input-label">What is your Nickname?</p>
          <input
            className="input-field"
            ref={inputFieldRef}
            placeholder="Player Name"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleJoinGame(e);
              }
            }}
          />
          {errorMessage && (
            <p className="modal-error-message">{errorMessage}</p>
          )}
        </div>

        <div className="modal-buttons-container">
          <button className="modal-join-game-button" onClick={handleJoinGame}>
            Join Game
          </button>
        </div>
      </div>
    </div>
  );
}
export default Lobby;
