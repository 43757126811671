import { io } from "socket.io-client";

export const URL =
  process.env.REACT_APP_VERCEL_ENV === "production"
    ? "https://doudizhu.herokuapp.com"
    : "http://localhost:8000";

// export const URL = "https://doudizhu.herokuapp.com/";
// export const URL = "http://localhost:8000";

export const socket = io(URL);
//
