import { Player, PlayerSelf } from "../types/types";

interface ScoreBoardProps {
  player_self: PlayerSelf;
  players: Player[];
}

const ScoreBoard = ({ player_self, players }: ScoreBoardProps) => {
  return (
    <div className="game-side-bar-scoreboard-container">
      <h1 className="game-side-bar-scoreboard-header">SCOREBOARD</h1>
      <div className="game-side-bar-scoreboard-players-container">
        {[...players]
          .sort((a, b) => b.score - a.score)
          .map((player: Player) => (
            <div className="game-side-bar-scoreboard-player" key={player.index}>
              <svg
                style={{
                  filter: `drop-shadow(0 0 6px ${player.color}`,
                }}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
                  fill={player.color}
                />
              </svg>
              <div className="game-side-bar-scoreboard-player-name">
                {player.name} {player_self.name === player.name && "(You)"}
              </div>
              <div
                className={`game-side-bar-scoreboard-player-score ${
                  player.score > 0
                    ? "positive"
                    : player.score < 0
                    ? "negative"
                    : "zero"
                }-score`}
              >
                {player.score}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ScoreBoard;
