import { socket } from "../socket";
import { GameStage, GameState } from "../types/types";

interface ActionButtonProps {
  gameState?: GameState;
  selectedCards?: { [key: number]: boolean };
  setSelectedCards?: any;
}

const handleStartGame = (gameId: string) => {
  return () => {
    socket.emit("start_game", {
      game_id: gameId,
    });
  };
};

const handleBid = (gameId: string, bid: number) => {
  return () => {
    socket.emit("bid", {
      game_id: gameId,
      bid: bid,
    });
  };
};

const handlePassBid = (gameId: string) => {
  return () => {
    socket.emit("pass_bid", {
      game_id: gameId,
    });
  };
};

const handlePlayCards = (
  gameId: string,
  cards: string[],
  setSelectedCards: any
) => {
  return () => {
    socket.emit("play_cards", {
      game_id: gameId,
      cards: cards,
    });
    setSelectedCards({} as { [key: number]: boolean });
  };
};

const handlePassTurn = (gameId: string) => {
  return () => {
    socket.emit("pass_turn", {
      game_id: gameId,
    });
  };
};

const handlePlayAgain = (gameId: string) => {
  return () => {
    socket.emit("play_again", {
      game_id: gameId,
    });
  };
};

const ActionButton = ({
  gameState,
  selectedCards,
  setSelectedCards,
}: ActionButtonProps) => {
  if (!gameState) {
    return undefined;
  }
  if (gameState?.game_stage === GameStage.LOBBY) {
    const len = gameState.players.length;
    const buttonText = `Start Game (${len} ${len > 1 ? "players" : "player"})`;
    const disabled = len < 3;
    return (
      <div className="game-actions-container">
        <button
          disabled={disabled}
          className="primary-button"
          onClick={handleStartGame(gameState.game_id)}
        >
          {buttonText}
        </button>
      </div>
    );
  }
  if (gameState?.game_stage === GameStage.BIDDING) {
    const disabled = (bid: number) => {
      return (
        gameState.player_self?.sid !==
          gameState.players[gameState.current_player_index].sid ||
        gameState?.game_bid >= bid
      );
    };
    return (
      <div className="game-actions-container">
        <button
          className="primary-button"
          onClick={handleBid(gameState.game_id, 1)}
          disabled={disabled(1)}
        >
          1
        </button>
        <button
          className="primary-button"
          onClick={handleBid(gameState.game_id, 2)}
          disabled={disabled(2)}
        >
          2
        </button>
        <button
          className="primary-button"
          onClick={handleBid(gameState.game_id, 3)}
          disabled={disabled(3)}
        >
          3
        </button>
        <button
          className="primary-button"
          onClick={handlePassBid(gameState.game_id)}
          disabled={disabled(4)}
        >
          Pass
        </button>
      </div>
    );
  }
  if (gameState?.game_stage === GameStage.PLAYING) {
    const cards = Object.entries(selectedCards)
      .filter(([_, selected]) => selected)
      .map(
        ([index, _]) =>
          `${gameState.player_self.cards[Number(index)].value}_${
            gameState.player_self.cards[Number(index)].suit
          }_regular`
      );
    const disabled =
      gameState.player_self?.sid !==
      gameState.players[gameState.current_player_index].sid;
    return (
      <div className="game-actions-container">
        {cards.length !== 0 && (
          <button
            className="secondary-button"
            onClick={() => {
              setSelectedCards({});
            }}
          >
            Clear Hand
          </button>
        )}
        <button
          className="primary-button"
          onClick={handlePlayCards(gameState.game_id, cards, setSelectedCards)}
          title={!disabled && cards.length === 0 ? "Select a card to play" : ""}
          disabled={disabled}
        >
          Play
        </button>
        <button
          className="secondary-button"
          onClick={handlePassTurn(gameState.game_id)}
          disabled={disabled || gameState.game_field.field_cards.length === 0}
        >
          Pass
        </button>
      </div>
    );
  }
  if (gameState?.game_stage === GameStage.FINISHED) {
    return (
      <div className="game-actions-container">
        <button
          className={"primary-button"}
          onClick={handlePlayAgain(gameState.game_id)}
        >
          Play Again
        </button>
      </div>
    );
  }
};

export default ActionButton;
