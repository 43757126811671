import { GameState, Player } from "../types/types";

interface PlayerFieldProps {
  player?: Player;
  position: string; // One of "you", "right", "left", "top".
  gameState?: GameState;
}

const PlayerField = ({ player, position, gameState }: PlayerFieldProps) => {
  return player ? (
    <div className={`game-player game-player-${position}`}>
      <div className="game-player-name">
        {player.name}
        {player.index === gameState?.landlord_index && (
          <div className="game-player-landlord-icon">地主</div>
        )}
      </div>
      <div
        className="game-player-cards-left"
        style={
          player.num_cards_left === 0
            ? { color: "red" }
            : player.num_cards_left > 8
            ? { color: "#B6F09C" }
            : { color: "#FFD147" }
        }
      >
        {player.num_cards_left
          ? player.num_cards_left.toString() +
            ` card${player.num_cards_left !== 1 ? "s" : ""} left`
          : ""}
      </div>
      <div
        className="game-player-active-icon"
        style={{
          visibility:
            gameState?.current_player_index === player.index
              ? "visible"
              : "hidden",
        }}
      />
      <div
        className="game-player-passed-icon"
        style={{
          visibility: gameState?.passed_players_idx.includes(player.index)
            ? "visible"
            : "hidden",
        }}
      />
    </div>
  ) : undefined;
};

export default PlayerField;
