import { GameStage, GameState } from "../types/types";
import ScoreBoard from "./ScoreBoard";

interface SidebarProps {
  gameState?: GameState;
}

const Sidebar = ({ gameState }: SidebarProps) => {
  return (
    <div className="game-side-bar">
        <div className="game-side-bar-code-container">
          <h1 className="game-side-bar-code-text">
            {window.location.pathname.split("/").pop()}
          </h1>
        </div>
        <ScoreBoard
          player_self={gameState?.player_self}
          players={gameState?.players ?? []}
        />
        <div className="game-side-bar-bid-container">
          {gameState?.game_stage === GameStage.PLAYING &&
            `Game bid: ${gameState?.game_bid}`}
        </div>
      </div>
  );
};

export default Sidebar;
